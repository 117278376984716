
import { computed, defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbsWithParams } from "@/core/helpers/breadcrumb";
import Entities from "@/components/base/common/Entities.vue";
import { LoadFilterObjects, LoadPanel } from "@/core/composite/composite";
import { Actions } from "@/store/enums/StoreEnums";
import store from "@/store";
import RecentCards from "@/components/base/common/RecentCards.vue";
import PolicyCard from "@/views/policy/PolicyCard.vue";

export default defineComponent({
  name: "Watching",
  components: {PolicyCard,  RecentCards, Entities},
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams('Watching Policies',
        [
          {link: false, router: '', text: 'Watching Policies'}
        ]
      )
    })
    const filterObject = ref({watching: true})
    const watchingPage = computed(()=>store.state.PolicyModule.watchingPage)
    return {
      watchingPage,
      ...LoadFilterObjects(Actions.LOAD_WATCHING_POLICIES, filterObject.value, ['client', 'insureds', 'product']),
      ...LoadPanel()
    }
  }
})
