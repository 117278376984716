import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PolicyCard = _resolveComponent("PolicyCard")!
  const _component_RecentCards = _resolveComponent("RecentCards")!
  const _component_Entities = _resolveComponent("Entities")!

  return (_openBlock(), _createBlock(_component_Entities, {
    items: _ctx.watchingPage.total,
    "view-card": true,
    "view-table": false,
    "enable-filter": false,
    "show-label": false,
    "display-view": false
  }, {
    card: _withCtx(() => [
      _createVNode(_component_RecentCards, {
        total: _ctx.watchingPage.total,
        "card-data": _ctx.watchingPage.data,
        "current-page": _ctx.filter.paginationPage,
        "rows-per-page": _ctx.filter.paginationSize,
        onCurrentChange: _ctx.handleUpdatePage
      }, {
        "card-info": _withCtx(({card: view}) => [
          _createVNode(_component_PolicyCard, { view: view }, null, 8, ["view"])
        ]),
        _: 1
      }, 8, ["total", "card-data", "current-page", "rows-per-page", "onCurrentChange"])
    ]),
    _: 1
  }, 8, ["items"]))
}